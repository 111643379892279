import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  name?: string;
}

export const Seo: React.FC<Props> = ({
  title = 'Personalized Astrology Book | Nordastro',
  description = 'First fully personalized astrology book with unique birth chart reading for self-growth, better relationships, life path, and career goals.',
  name = 'Personalized Astrology Book | Nordastro',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href={location.toString()} />
    {/* FB tags */}
    <meta property="og:url" content={'https://nordastro.com'} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
